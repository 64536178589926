import {
  Document,
  HeadingLevel,
  ImageRun,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  Footer,
} from "docx"

export class DocumentCreator {
  // tslint:disable-next-line: typedef
  create(
    organization_name,
    { src, width, height },
    [identities, experiences, educations, skills, interests]
  ) {
    const document = new Document({
      sections: [
        {
          footers: {
            default: new Footer({
              children: [this.createFooter(organization_name)],
            }),
          },
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: src,
                  transformation: {
                    width: width,
                    height: height,
                  },
                }),
              ],
            }),
            new Paragraph({
              text: identities,
              heading: HeadingLevel.TITLE,
            }),

            this.createHeading("Compétences"),
            this.createSubHeading("Compétences fonctionnelles"),
            this.createSkillList([]),
            new Paragraph({
              children: [],
            }),
            this.createSubHeading("Compétences techniques"),
            this.createSkillList(skills),
            new Paragraph({
              children: [],
            }),
            this.createHeading("Formations / Certifications"),
            ...educations
              .map((education) => {
                const arr = []
                arr.push(
                  new Paragraph({
                    children: [], // Just newline without text
                  })
                )

                arr.push(
                  this.createInstitutionHeader(
                    education.schoolName,
                    `${education.startDate} ${education.startDate &&
                      education.endDate &&
                      "-"} ${education.endDate}`
                  )
                )
                arr.push(
                  this.createRoleText(
                    `${education.fieldOfStudy} - ${education.degree}`
                  )
                )
                arr.push(
                  new Paragraph({
                    children: [], // Just newline without text
                  })
                )

                return arr
              })
              .reduce((prev, curr) => prev.concat(curr), []),

            this.createHeading("Expérience"),
            ...experiences
              .map((position) => {
                const arr = []
                arr.push(
                  new Paragraph({
                    children: [], // Just newline without text
                  })
                )
                arr.push(
                  this.createInstitutionHeader(
                    position.company.name,
                    this.createPositionDateText(
                      position.startDate,
                      position.endDate,
                      position.isCurrent
                    )
                  )
                )
                arr.push(this.createRoleText(position.title))
                arr.push(
                  new Paragraph({
                    children: [], // Just newline without text
                  })
                )
                arr.push(
                  new Paragraph({
                    text: "Cadre de la mission :",
                    children: [], // Just newline without text
                  })
                )
                arr.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Environnement technique :",
                        color: "#2e74b5",
                      }),
                    ],
                  })
                )
                arr.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Réalisations :",
                      }),
                    ],
                  })
                )
                if (position.summary.length) {
                  const bulletPoints = this.splitParagraphIntoBullets(
                    position.summary
                  )

                  if (bulletPoints.length) {
                    bulletPoints.forEach((bulletPoint) => {
                      arr.push(this.createBullet(bulletPoint))
                    })
                  }
                }

                arr.push(
                  new Paragraph({
                    children: [], // Just newline without text
                  })
                )

                return arr
              })
              .reduce((prev, curr) => prev.concat(curr), []),
          ],
        },
      ],
    })

    return document
  }

  createFooter(organization_name) {
    if (organization_name === "maltem_demo") {
      return (
        new Paragraph({
          children: [
            new TextRun({
              text: "Maltem Consulting Group",
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "8 place du marché, 92200 Neuilly sur Seine.",
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Tél +33 (0) 1 77 78 13 00 +33 (0) 1 77 78 13 01",
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "contact@maltem.com",
            }),
          ],
        })
      )
    }
  }

  createHeading(text) {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
    })
  }

  createSubHeading(text) {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
    })
  }

  createInstitutionHeader(institutionName, dateText) {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ],
      children: [
        new TextRun({
          text: institutionName,
          bold: true,
        }),
        new TextRun({
          text: `\t${dateText}`,
          bold: true,
        }),
      ],
    })
  }

  createRoleText(roleText) {
    return new Paragraph({
      children: [
        new TextRun({
          text: roleText,
          italics: true,
        }),
      ],
    })
  }

  createBullet(text) {
    return new Paragraph({
      text: text,
      bullet: {
        level: 0,
      },
    })
  }

  // tslint:disable-next-line:no-any
  createSkillList(skills) {
    if (skills.length) {
      return new Paragraph({
        children: [new TextRun(skills.join(", ") + ".")],
      })
    }
  }

  // tslint:disable-next-line:no-any
  createAchivementsList(achivements) {
    return achivements.map(
      (achievement) =>
        new Paragraph({
          text: achievement.name,
          bullet: {
            level: 0,
          },
        })
    )
  }

  createInterests(interests) {
    return new Paragraph({
      children: [new TextRun(interests)],
    })
  }

  splitParagraphIntoBullets(text) {
    return text.split("\n\n")
  }

  // tslint:disable-next-line:no-any
  createPositionDateText(startDate, endDate, isCurrent) {
    const startDateText = startDate
    const endDateText = isCurrent ? "Present" : endDate

    return `${startDateText} ${startDate && endDate && "-"} ${endDateText}`
  }

  getMonthFromInt(value) {
    switch (value) {
      case 1:
        return "Jan"
      case 2:
        return "Feb"
      case 3:
        return "Mar"
      case 4:
        return "Apr"
      case 5:
        return "May"
      case 6:
        return "Jun"
      case 7:
        return "Jul"
      case 8:
        return "Aug"
      case 9:
        return "Sept"
      case 10:
        return "Oct"
      case 11:
        return "Nov"
      case 12:
        return "Dec"
      default:
        return "N/A"
    }
  }
}
