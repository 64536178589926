import i18n from '@/plugins/vueI18n'
import lineJoint from './lineJoint';

const monthMap = {
  janvier: '00',
  février: '01',
  mars: '02',
  avril: '03',
  mai: '04',
  juin: '05',
  juillet: '06',
  août: '07',
  septembre: '08',
  octobre: '09',
  novembre: '10',
  décembre: '11',
};

const getMonth = month => monthMap[month.toLowerCase()];

export default function calculateExperience(data) {
  if(!data) return

  let totalExperience = 0;
  let monthDiff = 0;
  let years = '';
  let months = '';
  let result = '';

  data.forEach((item) => {
    if (item.year_end && item.year_start) {
      const exp = Number.parseInt(item.year_end, 10) - Number.parseInt(item.year_start, 10);

      if (item.month_start && item.month_end) {
        monthDiff = getMonth(item.month_end) - getMonth(item.month_start);

        if (monthDiff < 0) {
          totalExperience--;
          monthDiff += 11;
        }
      }

      totalExperience += exp;
    } else if (item.year_end || item.year_start) {
      totalExperience++;
    }
  });

  if (totalExperience) {
    if (totalExperience === 1) {
      years = `1 ${i18n.t('COMMON.LABELS.YEAR')}`;
    } else {
      years = `${totalExperience} ${i18n.t('COMMON.LABELS.YEARS')}`;
    }
  }

  if (monthDiff > 0) {
    if (monthDiff === 1) {
      months = `1 ${i18n.t('COMMON.LABELS.MONTH')}`;
    } else {
      months = `${monthDiff} ${i18n.t('COMMON.LABELS.MONTHES')}`;
    }
  }

  result = lineJoint(years, months);

  return result;
};
