<template>
  <div class="page page-xranking-all-matching-cvs">
    <PageHeader
      icon="mdi-page-next-outline"
      :title="$t('INSIGHT.TITLE')"
      class="pt-2"
    >
      <template slot="nav" v-if="libraryShown">
        <small><strong>{{ $tc('JOB.TITLE_COUNT', count) }}</strong></small>
      </template>
    </PageHeader> 

    <Loading :value="loading" color="#f0f0f0" z-index="1" />
    <List
      v-if="libraryShown"
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :selected="selected"
      :selectable="false"
      :pagination="true"
      :loading="loading"
      @page="changePage"
      @reload="validateRaOrg"
      @select="selectItems"
    >
      <template v-slot:header="">
        <div class="col-4">
          <v-text-field
            ref="where.title"
            rounded
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </div>
      </template>


      <template v-slot:item-list="item">
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            <strong>{{ item.identities.data.firstname }}</strong>
          </v-list-item-title>
        </v-list-item-content>
        
        <v-list-item-action class="ml-3 date">
          {{ $moment(item.created_at).fromNow() }}
        </v-list-item-action>

        <v-list-item-action>
          <div>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  @click.stop="loadCvInfo(item.id)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.DOWNLOAD') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  @click.stop="getRemoveCV(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.TOOLTIP.DELETE') }}: {{$t('CV.TITLE')}} {{ `${(item.identities.data.firstname)}` }} 
            </v-tooltip>
          </div>
        </v-list-item-action>
      </template>
    </List>

    <div class="height-100 mt-4 ml-4 mr-4" v-if="!libraryShown">

      <!-- upload/parsing in progress -->
      <div class="height-100" style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%" v-if="!isParsed && parsingInProgress">
        <span class="mb-8" style="font-size: 30px; color: #E0BC37; font-weight: 800;">
          {{$t("INSIGHT.PARSING_PROGRESS")}}
        </span>
        <v-progress-circular
          indeterminate
          color="accent"
          size="150"
          :width="8"
        ></v-progress-circular>
      </div>

      <!-- parsing done -->
      <div class="height-100" style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%" v-if="isParsed && !parsingInProgress">
        <div v-ripple class="file-converted" outlined @click="loadCvInfo(data.id)">
          <v-icon size="100">mdi-file-word</v-icon>
          <div class="file-converted-bottom-section">
          {{data.original_filename}}
          <v-icon>mdi-download</v-icon>
          </div>
        </div>
        
        <div>
          <v-btn depressed large color="accent" class="mt-5" @click="resetParsing()">
            <v-icon class="mr-4">mdi-refresh</v-icon>
            {{$t("INSIGHT.NEW_PARSING")}}
          </v-btn>
        </div>
      </div>

      <!-- drag n drop screen -->
      <div class="height-100" v-if="!isParsed && !parsingInProgress">
        <ranking-dropzone 
          v-model="files"
          maxLength="1"
          type-dropzone="upload"
          @change="checkFile"
          :validFile="validFile"
        >
          <template v-slot:button>
            <v-btn depressed large color="success" class="mt-5" @click="sendFile()">
              {{ $t('INSIGHT.PARSE_BUTTON') }}
            </v-btn>
          </template>
          <template v-slot:second-button>
            <v-btn
              color="accent"
              class="page-xranking-all-matching-cvs__button-to-library d-flex"
              @click="toggleLibrary()"
            >
              {{libraryShown ? $t("INSIGHT.FORMAT") : $t('INSIGHT.LIBRARY') }}
            </v-btn>
          </template>
        </ranking-dropzone> 
      </div>
      
    </div>
    

    <div
      v-if="!cvMatch.length && noData"
      class="page-xranking-all-matching-cvs__no-data mt-10"
    >
      {{ $t("COMMON.NO_DATA") }}
    </div>

    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="cancelRemoval"
      @select="selectItems"
    />

  </div>
</template>

<script>
import { mapState, mapGetters,  mapActions } from "vuex"
import PageHeader from '@/components/PageHeader'
import List from '@/components/List'
import Loading from '@/components/Loading'
import RankingDropzone from '@/components/RankingDropzone'
import calculateExperience from '@/utils/calculateExperience'
import DialogRemove from '@/components/DialogRemove'

import { saveAs } from "file-saver";
import { Packer } from "docx";
import { DocumentCreator } from "../../../utils/dataProcess/cv-generator";

export default {
  name: "PageXrankingAllTransformation",
  components: {
    PageHeader,
    List,
    Loading,
    RankingDropzone,
    DialogRemove,
  },
  data: (_this) => ({
    items: [],
    data: [],
    selected: [],
    count: null,
    organization: {},
    companyLogo: {
      base64data: null,
      width: null,
      height: null,
    },
    loading: false,
    parsingInProgress: false,
    libraryShown: false,
    isParsed: false,
    historyJobs: [],
    offset: 0,
    limit: 25,
    page: 1,
    showDialogRemove: false,
    cvNative: null,
    colors: ['#FF4E11', '#FF8E15', '#FAB733', '#ACB334', '#69B34C'],
    color: 'rgba(0,0,0, .08)',
    noData: false,
    files: [],
    validFile: false  ,
    maxFileSize: 31457280,
  }),
  watch: {
    files: {
      handler (n) {
        console.log(n);
      }
    }
  },
  computed: {
    ...mapState('user', {
      userOfficeId: state => state.user.office_id,
    }),

    ...mapGetters({
      cv: 'cv/getCV',
      fullName: 'cv/getFullName',
      cvMatch: 'cv/getCVMatch',
      personalInfoOnMatch: 'cv/getPersonalInfoOnMatch',
      token: 'user/getToken',
    }),

    cvId() {
      return this.$route.params.id
    },

    currentPageData() {
      const start = (this.page - 1) * this.limit;
      const end = start + this.limit;
      const cloneCVMatch = JSON.parse(JSON.stringify(this.cvMatch))

      return cloneCVMatch.slice(start, end);
    },

    experience() {
      return calculateExperience(this.personalInfoOnMatch.experiences)
    },
  },
  mounted() {
    this.validateRaOrg();
  },
  methods: {
    ...mapActions({
      addCV: 'cv/addCV',
      addCVMatch: 'cv/addCVMatch',
    }),

    cancelRemoval() {
      this.toogleDialogRemove(false);
      this.selected = []
    },

    selectItem (item) {
      for (let i=0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i, 1)
      }

      return this.selected.push(item)
    },

    selectItems(arr) {
      this.selected = arr
    },

    toggleLibrary () {
      this.libraryShown = !this.libraryShown;
    },

    resetParsing () {
      this.isParsed = false;
      this.libraryShown = false;
    },

    async sendFile() {
      const file = this.files[0];
            
      const formData = new FormData();
      formData.append('office_id', this.userOfficeId);
      formData.append('cv', file, file.name);

      let result = {};

      try {
        this.parsingInProgress = true;
        result = await this.$services.xfiles_ra.file.upload(formData);
      } catch(e) {
        this.parsingInProgress = false;
      }

      if (!result.data) {
        console.log('error')
      } else {
        this.checkFileDetails(result.data.fileId);
      }
    },

    getRemoveCV(item) {
      this.selectItem(item)
      this.toogleDialogRemove(true)
    },

    toogleDialogRemove(state) {
      this.showDialogRemove = state
    },
    
    async confirmRemoveItems() {
      const ids = this.selected.map(v => v.id)
  
      for (let i = 0; i < ids.length; i++) {
        try {
          this.loading = true
          const { data: { result }, status } = await this.$services.xrankingcv_ra.cv.remove(ids[i])
          this.selected = this.selected.filter(item => item.id !== ids[i])
          this.loading = false
          this.$store.dispatch('messenger/add', {type: 'success', code: 'XRANKING.DELETE_CV_SUCCESSFULLY', message: result, time: 3000 })
          await this.searchItems()
        } catch({ message, code }) {
          this.$store.dispatch('messenger/add', {type: 'error', code, message, time: 5000 })
          await this.searchItems()
        }
      }
    },

    checkFileDetails (fileId) {
      let ReccFileId = fileId;
      this.$services.xfiles_ra.file.isParsed(fileId)
      .then(res => {
        if (res.data.status !== "processed" && res.data.status !== "errored" ) {
          setTimeout(() => this.checkFileDetails(ReccFileId), 8000)
        }
        else {
          this.parsingInProgress = false
          if (res.data.status === "errored") {
            this.isParsed = false
            this.$store.dispatch('messenger/add', 
            { type: 'error', code: 'DEFAULT_MESSAGE', message: 'XRANKING.SERVER_ERROR', time: 5000 })
          }
          else {
            this.isParsed = true
            this.data = res.data
          }
        }
      })
    },

    fullname({ identities }) {
      const { data: { firstname, lastname }} = identities
      return lastname ? `${lastname} ${firstname}`: firstname
    },

    checkFile () {
      let fileSize
      this.files.forEach(file => {
        const { size } = file;
        fileSize = size ? size : 0;
      })

      if (fileSize > 0 && fileSize < this.maxFileSize) {
        this.validFile = true;
        return;
      }

      this.$store.dispatch('messenger/add', 
        { type: 'error', code: 'DEFAULT_MESSAGE', message: this.$t('MESSAGE.CREATIVE.UNSUPPORTED_SIZE'), time: 5000 });

      this.validFile = false;
    },

    loadCompanyLogoAsBlob() {

      // this.organization.logo_url
      const request = new Request("https://xtramilefileshare.blob.core.windows.net/xtramile-prod-public-fileshare/logo/logo_xtramile.png")
      fetch(request)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)

          reader.onloadend = () => {
            let base64data = reader.result

            const img = new Image()
            let vueState = this;
            img.src = base64data

            img.onload = function () {
              vueState.companyLogo.height = img.height
              vueState.companyLogo.width = img.width
            }

            vueState.companyLogo.base64data = base64data
          }
        })
    },

    loadCvInfo(offerId) {
      this.$services.xranking_ra.v2_cvs.getById(offerId)
        .then((response) => {

          this.generateDocx(response.data.cv, {
            src: this.companyLogo.base64data,
            height: this.companyLogo.height / 3,
            width: this.companyLogo.width / 3,
          });

        })
        .catch((err) => {
          let message = "Erreur de parsing"
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.ERROR_PARSING',
            message, time: 5000 });
        })
    },

    formatData (data) {
      const identities = data.identities.data.firstname;

      const experiences = data.experiences.data.map((exp) => ({
        isCurrent: false,
        title: exp.occupations[0]?.ref?.value || exp.occupations[0]?.plaintext,
        summary: exp.mission.join("\n"),
        startDate: exp.date_start.plaintext,
        endDate: exp.date_end.plaintext,
        company: {
          name: exp.organisation_name.plaintext,
        },
      }));

      const educations = data.educations.data.map((educ) => ({
        degree: educ.level.plaintext,
        fieldOfStudy: educ.specialisation.plaintext,
        schoolName: educ.school_name,
        startDate: educ.date_start.plaintext,
        endDate: educ.date_end.plaintext,
      }));

      const skills = [];
      data.skills.data.forEach((skill) => {
        skill.hard_skill.forEach((hsk) => skills.push(hsk.plaintext));
      });
      const interests = data.interests.infos.interests.map(
        (interest) => interest.plaintext
      );
      return [identities, experiences, educations, skills, interests];
    },

     generateDocx (data, logo) {
      const documentCreator = new DocumentCreator();

      const [
        identities,
        experiences,
        educations,
        skills,
        interests,
      ] = this.formatData(data);

      const doc = documentCreator.create(this.organization.name, logo, [
        identities,
        experiences,
        educations,
        skills,
        interests,
      ]);

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${data.identities.data.firstname}.docx`);
      });
    },

    validateRaOrg() {
      this.$services.xusers_ra.organization.describe()
        .then((response) => {
          if (response.data.organization.id !== this.userOfficeId) {
            this.$store.dispatch('messenger/add', {type: 'warning', code: 'XRANKING.MISSING_OFFICE_ORG_MAPPING',
              message: `Office ${this.userOfficeId} does not have a corresponding xusers organization mapped`, time: 5000 });
          } else {
            //load cv info
            this.organization = response.data.organization
            this.searchItems()
            this.loadCompanyLogoAsBlob();
          }
        })
        .catch(e => {
          let message = `Error validating organization for office ${this.userOfficeId}`
          if (e.error && e.error.code && e.error.message) {
            message = `${e.error.code} - ${e.error.message}`
          }
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.ORGANIZATION_ERROR',
            message, time: 5000 })
        })
    },

    changePage(page) {
      this.page = page
      this.offset = this.page - 1
      this.searchItems()
    },
    
    
    searchItems() {
      const query_offers = {
        page: this.page,
        size: this.limit,
        sort: '-date'
      }
      
      this.$services.xranking_ra.v2_cvs.get(query_offers)
        .then((response) => {
          this.items = response.data.data
          this.count = response.data.total.value
        });

      this.offset = this.limit * this.page - 1; 
    },

  },
}
</script>

<style lang="scss">
.page-xranking-all-matching-cvs {
  position: relative;

  .v-btn.v-btn--icon.v-btn--round.theme--light.v-size--x-large {
    background-color: $color-dark-blue;
    color: $color-white;

    &:hover {
      opacity: 0.6;
    }
  }

  .list-actions {
    margin-bottom: 85px;
    
    .v-btn--icon,
    .theme--light.v-btn.v-btn--has-bg {
      background-color: $color-dark-blue;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-dark-blue;
      }
    }
  }

  .page-xranking-all-matching-cvs__item-description,
  .page-xranking-all-matching-cvs__item-office,
  .page-xranking-all-matching-cvs__item-date {
    white-space: pre-wrap;
  }

  .page-xranking-all-matching-cvs__item-content-company {
    flex: unset;
    min-width: 200px;
    max-width: 200px;
  }

  .page-xranking-all-matching-cvs__item-content-office,
  .page-xranking-all-matching-cvs__item-content-score {
    flex: unset;
    min-width: 165px;
    max-width: 165px;
  }

  .page-xranking-all-matching-cvs__button-to-library.v-btn:not(.v-btn--round).v-size--default {
    margin-left: 8px;

    @media (max-width: 1280px) {
      margin-top: 12px;
      margin-left: 0;
    }
  }
}

.page-xranking-all-matching-cvs__detail-info {
  cursor: pointer;

  .v-icon {
    color: $color-dark-blue;
  }
}

.page-xranking-all-matching-cvs__personal-info {
  font-size: 16x;
  line-height: 30px;
  color: $color-grey-middle;
}

.page-xranking-all-matching-cvs__position {
  color: $color-black;
}

.page-xranking-all-matching-cvs__personal-info-icon {
  color: $color-dark-blue;
}

.page .page-xranking-all-matching-cvs__list-header {
  display: flex;
  flex-direction: row;
}

.page-xranking-all-matching-cvs__header-actions-label {
  display: flex;
  flex-direction: row;
}

.page-xranking-all-matching-cvs__no-data {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $color-dark-blue;
}

.page-xranking-all-matching-cvs__score-item {
  min-width: 30px;
  min-height: 15px;
  margin: 1px;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.file-converted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border: 2px solid rgba(0,0,0, 0.1);
  border-radius: 5px;
  margin: 2px;
  min-height: 170px !important;
  background-color: #fff;
  min-width: 200px !important;
  justify-content: space-between;
  margin: 0;
  
  cursor: pointer;

  /* max-width: 200px; */ 
}

.file-converted:hover {
  background-color: #f9f9f9;
  border: 2px solid #f9f9f9;

}

.file-converted:active {
  background-color: #eee;
  border: 2px solid #eee;
}

.file-converted-bottom-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
}

.file-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px 0 8px;
  width: 200px
}
</style>
